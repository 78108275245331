import {pluralize, priceFormat} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../offer/helpers/OfferType";
import {ICreditData} from "../types/ICreditData";

const yearsPluralize = pluralize(["rok", "lata", "lat"]);

export const getCreditInfo = (creditData: Partial<ICreditData>) => {
    const infoTable: {
        label: string;
        value: string;
    }[] = [];

    if (creditData.property_price) {
        infoTable.push({label: "Cena nieruchomości", value: `${priceFormat(creditData.property_price)}`});
    }
    if (creditData.income) {
        infoTable.push({label: "Średnie miesięczne zarobki NETTO", value: `${priceFormat(creditData.income)}`});
    }
    if (creditData.own_contribution || creditData.contribution_percent) {
        infoTable.push({
            label: "Wkład własny",
            value: `${creditData.contribution_percent ? `${creditData.contribution_percent}%, ` : ""}${creditData.own_contribution ? priceFormat(creditData.own_contribution) : ""}`
        });
    }
    if (creditData.term_of_the_loan) {
        infoTable.push({label: "Okres kredytowania", value: `${creditData.term_of_the_loan} ${yearsPluralize(creditData.term_of_the_loan)}`});
    }
    if (creditData.age) {
        infoTable.push({label: "Twój wiek", value: creditData.age.toString()});
    }
    if (creditData.applicants_count) {
        infoTable.push({label: "Liczba wnioskodawców", value: creditData.applicants_count.toString()});
    }
    if (creditData.property_type) {
        infoTable.push({label: "Rodzaj nieruchomości", value: getOfferTypeNameCapital(creditData.property_type)});
    }

    return infoTable;
};
