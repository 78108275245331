import {MouseEvent, useId} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {FormikForm} from "@pg-design/formik-utils";
import {borderRadius, calculateRemSize, p} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {hitMktOptinClick} from "../../../tracking/hit_mkt_optin_click";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {RodoWithdrawConsent} from "../components/RodoWithdrawConsent";

interface IProps {
    showPropertyNotificationField: boolean;
    className?: string;
}

export function MktOptinField(props: IProps) {
    const {showPropertyNotificationField, className} = props;
    const theme = useTheme();
    const id = useId();
    const {hidePropertyNotification} = useApplicationFormContext();

    if (hidePropertyNotification || !showPropertyNotificationField) {
        return null;
    }

    return (
        <div css={mktOptinWrap} className={className}>
            <span css={mktDescription}>
                <InfoIcon wrapperSize="1.6" wrapperColor="transparent" size="1.6" />
                <Text variant="info_txt_3" color={theme.colors.gray[800]} as="span">
                    Zaznacz poniższą zgodę, aby rynekpierwotny.pl mógł się z Tobą kontaktować.
                </Text>
            </span>
            <FormikForm.Checkbox
                id={id}
                name="new_property_notification_consent"
                labelContent={
                    <span>
                        Poproszę o przesyłanie szczegółów dotyczących nowych nieruchomości i rekomendacji drogą mailową lub telefoniczną.
                        <br />
                        <RodoWithdrawConsent popoverZindex={10001} />
                    </span>
                }
                css={checkboxStyle}
                onClick={(event: MouseEvent<HTMLInputElement>) => {
                    hitMktOptinClick(event.currentTarget.checked, 1);
                }}
            />
        </div>
    );
}

// Styles
const mktOptinWrap = (theme: Theme) => css`
    ${borderRadius(1)};
    background: ${theme.colors.gray[300]};
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    ${p(1.5)}
`;

const mktDescription = css`
    display: flex;
    gap: ${calculateRemSize(1)};
`;

const checkboxStyle = css`
    align-items: flex-start;
    ${p(1, 0)}
`;
