import React from "react";
import {css} from "@emotion/react";

import {OfferType} from "../../../../offer/types/OfferType";
import {PropertyInformation} from "../../PropertyInformation";
import {useApplicationFormContext} from "../ApplicationFormContext";

export const PropertyInfo = ({className}: {className?: string}) => {
    const {property, offer, creditData} = useApplicationFormContext();

    if (!property || !offer) {
        return null;
    }

    const {number, area, rooms, floors, floor} = property;

    return (
        <PropertyInformation
            className={className}
            css={propertyInfoStyle}
            number={number}
            area={area}
            rooms={rooms}
            floor={offer.type === OfferType.HOUSE ? floors : floor}
            propertyType={offer.type}
            creditData={creditData}
        />
    );
};

const propertyInfoStyle = css`
    margin: 0;
`;
