import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IABTestingState} from "../interfaces/IABTestingState";
import {ABTestDataLayerQueue} from "../utils/ab_test_data_layer_queue";
import {useABTestVariant} from "./useEperimentVariant";

export interface IProps {
    disableEventPush?: boolean;
    pushEventOnlyOnce?: true;
    id: string;
    variants: number;
    withSSR?: boolean;
}

export const useABTest = (props: IProps) => {
    const [isClient, setIsClient] = useState<boolean>(false);

    const variant = useABTestVariant(props.id);

    const isInitialized = useSelector((state: IABTestingState) => state.abTesting.isInitialized);

    useEffect(() => {
        const isVariantValid = typeof variant === "number" && Number.isFinite(variant) && variant <= props.variants;

        if (!props.disableEventPush && isVariantValid) {
            useABTest.dataLayerQueue.pushEvent({id: props.id, variant: variant.toString(), pushOnlyOnce: props.pushEventOnlyOnce});
        }

        /*
         * If variant is not valid and ABTesting instance was initialized that means that variant for AB test was not generated.
         * The reason may be wrong `paths` configuration for AB Test. In such cases we need to render default (props.children[0]) component.
         */
        if ((!props.withSSR && !isClient && isVariantValid) || (!isVariantValid && !isClient && isInitialized)) {
            setIsClient(true);
        }
    }, [variant]);

    return {
        canABTestBeRendered: props.withSSR || isClient,
        abTestVariant: variant
    };
};

useABTest.dataLayerQueue = ABTestDataLayerQueue();
