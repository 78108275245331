import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {FormikForm} from "@pg-design/formik-utils";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    m,
    maxWidth,
    mb,
    minHeight,
    minWidth,
    mr,
    mv,
    onDesktop,
    p,
    pointer,
    position
} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {getOfferTypeName} from "../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../offer/types/OfferType";
import {IApplicationVendor} from "../../../types/IApplicationVendor";
import {RodoFormInfo} from "../../RodoFormInfo";
import {TermsAcceptation} from "../../TermsAcceptation";
import {OptIns} from "../fields/OptIns";
import {YourData} from "../fields/YourData";
import {PropertyInfo} from "./PropertyInfo";

const walkImage = require("../../../images/walk.png");

interface IProps {
    vendor: IApplicationVendor | null;
    leadIsSubmitting: boolean;
    formPreviouslySent: boolean;
    onCloseModal: (forceClose?: boolean) => void;
    showPropertyNotificationField: boolean;
    showRentalPriceField: boolean;
    showEmailField?: boolean;
    showPhoneField?: boolean;
    showNameField?: boolean;
    showAllInputFields?: () => void;
    isAuthenticated?: boolean;
    hideSubmitButton?: boolean;
    offerPrivacyPolicyUrl?: string | null;
    offerPersonalDataProcessorName?: string;
    offerPersonalDataProcessorUrl?: string | null;
    copyType?: FinancingServicesCopyTypes;
    offerType?: OfferType;
}

export const ApplicationCreditForm = (props: IProps) => {
    const {
        onCloseModal,
        showEmailField = true,
        showPhoneField = true,
        showNameField = true,
        showAllInputFields,
        showPropertyNotificationField,
        showRentalPriceField,
        leadIsSubmitting,
        isAuthenticated,
        formPreviouslySent,
        hideSubmitButton,
        vendor,
        offerPrivacyPolicyUrl,
        offerPersonalDataProcessorName,
        offerPersonalDataProcessorUrl,
        copyType = FinancingServicesCopyTypes.NONE_PROPERTY
    } = props;

    const onShowAllInputFieldsClick = () => {
        if (showAllInputFields) {
            showAllInputFields();
        }
    };

    return (
        <>
            <div css={creditApplicationLeft}>
                <Image src={walkImage} alt="Przeprowadzimy cię od A do B" height="120" width="250" css={imgStyle} />
                <Text as="p" variant="headline_6" align="center" css={m(3, 0, 4, 0)}>
                    Przeprowadzimy Cię do zakupu nieruchomości w kilku krokach
                </Text>
                <div css={stepsWrapper}>
                    {stepsTexts.map((phrase, index) => (
                        <div css={stepStyle} key={index}>
                            <Text as="div" variant="headline_6" css={stepNumberStyle}>
                                {index + 1}
                            </Text>
                            <Text variant="info_txt_1">{phrase}</Text>
                        </div>
                    ))}
                </div>
            </div>

            <div css={creditApplicationRight}>
                <div css={closeHolder} onClick={() => onCloseModal(false)}>
                    <CloseIcon size="2.4" />
                </div>

                <Text as="p" variant="headline_6" css={mb(0.5)}>
                    Zapytaj o {props.offerType ? getOfferTypeName(props.offerType) : "mieszkanie"} z kredytem
                </Text>
                <Text variant="info_txt_1">Zapytaj dewelopera o ofertę i skorzystaj z bezpłatnej porady kredytowej</Text>

                <PropertyInfo css={mv(3.5)} />

                <YourData
                    showEmailField={showEmailField}
                    showPhoneField={showPhoneField}
                    showNameField={showNameField}
                    onShowAllInputFieldsClick={onShowAllInputFieldsClick}
                    hideTopLabel
                    css={yourDataStyle}
                />

                <OptIns
                    showPropertyNotificationField={showPropertyNotificationField}
                    showFinancingServicesField={false}
                    showPropertyRentalPriceField={showRentalPriceField}
                    copyType={copyType}
                    css={mb(2)}
                />

                <div>
                    {!hideSubmitButton && (
                        <SubmitButton type="submit" variant="filled_primary" disabled={leadIsSubmitting} css={mb(1)}>
                            {leadIsSubmitting ? <Loader /> : "Wyślij"}
                        </SubmitButton>
                    )}

                    {(!isAuthenticated || !formPreviouslySent) && <TermsAcceptation />}
                </div>

                <RodoFormInfo
                    vendor={vendor}
                    offerPrivacyPolicyUrl={offerPrivacyPolicyUrl}
                    offerPersonalDataProcessorName={offerPersonalDataProcessorName}
                    offerPersonalDataProcessorUrl={offerPersonalDataProcessorUrl}
                    isCredipass
                />
            </div>
        </>
    );
};

const stepsTexts = [
    "Ekspert kredytowy Credipass skontaktuje się z Tobą w celu przeprowadzenia pierwszej niezobowiązującej rozmowy.",
    "Na podstawie informacji przekazanych ekspertowi zostanie przygotowana dla Ciebie kalkulacja zdolności kredytowej.",
    "Od dewelopera otrzymasz dokładne informacje o nieruchomości wraz z ceną.",
    "Poznasz dokładną ratę kredytu w różnych najbardziej optymalnych wariantach."
];

const creditApplicationLeft = (theme: Theme) => css`
    ${flex("center", "flex-start")};
    ${flexDirection("column")};
    ${p(3)};
    ${backgroundColor(theme.colors.gray[100])};

    ${onDesktop(maxWidth("350px"))};
`;

const stepsWrapper = css`
    ${maxWidth("350px")};
    align-self: center;
`;

const stepStyle = css`
    ${flex()};
    ${flexDirection("row")};
    ${mb(3)};
`;

const stepNumberStyle = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    ${mr(2)};
    ${borderRadius(4)};
    ${backgroundColor(theme.colors.primary)};
    width: ${calculateRemSize(4)};
    height: ${calculateRemSize(4)};
    ${minWidth(calculateRemSize(4))};
    ${minHeight(calculateRemSize(4))};
`;

const closeHolder = css`
    ${pointer};
    ${position("absolute")};
    top: ${calculateRemSize(3)};
    right: ${calculateRemSize(3)};
`;

const creditApplicationRight = css`
    ${flex()};
    ${flexDirection("column")};
    ${p(3)};
    ${backgroundColor("#fff")};

    ${onDesktop(maxWidth("450px"))};
`;

const imgStyle = css`
    align-self: center;
`;

const SubmitButton = styled(FormikForm.Button)`
    width: 100%;
`;

export const yourDataStyle = (theme: Theme) => css`
    margin: 0 0 1.2rem 0;

    @media (min-width: ${theme.breakpoints.md}) {
        margin: 0 0 2.6rem 0;
    }
`;
